import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import '../components/LayoutWhite/style.scss'
import Footer from '../components/Footer/index'
import HeaderWhite from 'components/HeaderWhite/index'
import PortableText from '../components/portableText'

export default function Home() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings(_id: { eq: "3e2d2c6e-61a5-4c03-bc00-ff620687a407" }) {
        title
        _rawHomepageText(resolveReferences: { maxDepth: 5 })
      }
    }
  `)
  const siteSettings = (data || {}).sanitySiteSettings

  return (
    <div className="container-fluid d-flex h-100 home-page-white">
      <div className="col-12 my-auto">
        <HeaderWhite />
        <p className="lead mb-6 pb-6 text-gray">
          <br />
          <br />
          <br />
          <div align="center">
            <PortableText blocks={siteSettings._rawHomepageText} />
          </div>
        </p>
        <Footer />
      </div>
    </div>
  )
}
